<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title> เบิกเงิน </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            <v-btn
              class="mx-3"
              fab
              dark
              color="indigo"
              v-on:click="
                $router.push('/Finance/WidenEMP/History?Type=WidenEmp')
              "
            >
              <v-icon dark>fa fa-history</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-data-table
          :items-per-page="10"
          :headers="headers"
          :items="desserts"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="ค้นหา"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mx-2 green" fab dark small @click="Paid(item)">
              <v-icon>fa fa-coins</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      desserts: [],
      loading: true,
      search: "",
      headers: [
        {
          text: "ประเภท",
          align: "left",
          sortable: true,
          value: "UserLevelText.TH",
          width: 100,
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: true,
          value: "UserName",
          width: 80,
        },
        {
          text: "จำนวนเงิน",
          align: "right",
          sortable: true,
          value: "Total",
          width: 100,
        },
        {
          text: "รับแล้ว",
          align: "right",
          sortable: true,
          value: "Receive",
          width: 100,
        },
        {
          text: "เบิกแล้ว",
          align: "right",
          sortable: true,
          value: "Paid",
          width: 80,
        },
        {
          text: "ค้าง",
          align: "right",
          sortable: true,
          value: "Stale",
          width: 80,
        },
        { width: 50, text: "", value: "action", sortable: false },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select();
    });
  },
  methods: {
    async Select() {
      this.loading = true;
      const result = await axios.post("/api/v1/select/WidenEMP/user", {
        Search: "",
        Type: "A",
      });
      if (result.status == 200) {
        this.desserts = result.data;
      }
      this.loading = false;
    },
    Paid(v) {
      this.$router.push("/Finance/WidenEMP/AddEmp?UserId=" + v.UserId);
    },
  },
};
</script>
